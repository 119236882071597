/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/animate.css"
// import "./src/styles/bootstrap.min.css"
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./src/styles/bootstrap.scss"

import "./src/styles/flaticon.css"
import "./src/styles/line-awesome.min.css"
import "./src/styles/menumaker.css"
import "./src/styles/owl.carousel.min.css"

import "./src/styles/slicknav.min.css"
import "./src/styles/style.css"
import "./src/styles/responsive.css"
import "./src/styles/react-burger.css"
